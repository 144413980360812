/* AppLayout.css */
html, body, #root, .layout {
    height: 100%;
    margin: 0;
    padding: 0;
  }
  
  .layout {
    display: flex;
    flex-direction: column;
  }

  .layout-content {
    flex: 1;
    overflow: auto;
  }
  
  .header {
    background: white;
    padding: 0 16px;
    z-index: 10; /* Ensure header is above the content */
  }
  
  .header-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .header-profile {
    display: flex;
    align-items: center;
  }
  
  .search-row {
    margin-top: 10px;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  
  .content {
    flex: 1;
    display: flex;
    flex-direction: column;
    background: white;
  }
  
  .footer {
    bottom: 0;
    position: fixed;
    width: 100%;
    background-color: white;
    padding-top: 10px;
    padding-bottom: 0px;
    padding-right: 0px;
    padding-left: 0px;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
    z-index: 10; /* Ensure footer is above the content */
  }
  
  @media (max-width: 768px) {
    .header {
      height: auto;
      padding: 16px;
      padding-bottom: 0px;
    }
    .menu-icon {
      display: block;
    }
    .layout-content {
      padding-bottom: 80px !important; /* To accommodate the footer */
    }
    .footer {
        display: block;
    }
    .site-layout-content {
        padding-bottom: 80px !important;
    }
  }
  
  @media (min-width: 769px) {
    .header {
        height: auto;
        padding: 16px;
        padding-bottom: 0px;
    }
    .menu-icon {
      /* display: none; */
    }
    .footer {
      display: none;
    }
    .ant-menu-item {
        padding: 16px 24px !important;
        font-size: 16px !important;
        height: auto !important;
        line-height: normal !important;
    }
  }
  